import React from "react";
import ProgressGraph from "../components/ProgressGraph";
import RatioGraph from "../components/RatioGraph";

import logo from   "./images/logo-black.svg"
import star from "./images/star.png"
import estar from "./images/Estar.png"
import hstar from "./images/Hstar.png"
import iconv from "./images/iconv.png"
import { TextSize } from "victory-core";

const MainDashboard = (props) => {
    const stateName = (props.stateName) ? props.stateName : "";
    const selectedSession = (props.selectedSession) ? props.selectedSession : "";
    const totalDashbord = (props.totalDashbord) ? props.totalDashbord : "";
    const currentDistrict = (props.currentDistrict) ? props.currentDistrict : "";
    const currentBlock = (props.currentBlock) ? props.currentBlock : "";
    const topSchools = (props.topSchools) ? props.topSchools : "";
    
    function DateDisplay() {
        let makeDate = new Date();
        const currYear = makeDate.getFullYear();
        let startMonth = 'April';
        let currentSession = currYear+'-'+(currYear+1)

        if (currentSession === selectedSession) {
            let startYear = currentSession.split("-")[0]
            const currDay = makeDate.getDate();
            let currMonth = ''
            if (currDay >= 25) {
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            } else {
                makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
                currMonth = makeDate.toLocaleString([], {
                    month: 'long',
                });
            }
            if (currMonth === startMonth) {
                return <p className="duration">{'April '+startYear}</p>;
            } else {
                return <p className="duration">{'April '+startYear+' - '+currMonth+' '+currYear}</p>;
            }
        } else {
            return <p className="duration">{'April '+selectedSession.split("-")[0]+' - March '+selectedSession.split("-")[1]}</p>;
        }
    }

    const returnStar = (length) => {
        if (length === 0) {
          return (
            <>
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if(length <= 0.5) {
            return (
              <>
                <img src={hstar} alt="hstar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 1) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 1.5) {
            return (
              <>
                 <img src={star} alt="star"  />
                 <img src={hstar} alt="hstar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 2) {
          return (
            <>
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 2.5) {
          return (
            <>
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={hstar} alt="hstar"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 3) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={estar} alt="estar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 3.5) {
          return (
            <>
               <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={star} alt="star"  />
              <img src={hstar} alt="hstar"  />
              <img src={estar} alt="estar"  />
            </>
          );
        }else if (length <= 4) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={estar} alt="estar"  />
              </>
            );
          }else if (length <= 4.5) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={hstar} alt="hstar"  />
              </>
            );
          }else if (length <= 5) {
            return (
              <>
                 <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
                <img src={star} alt="star"  />
              </>
            );
          }
    };
      

    const districtDashboard = (
        <div>
            {/* <!-- Block Section --> */}
            <div className="row bg-sec">
                <div className="col-md-3 d-flex pt-4 pb-4" style={{justifyContent:'space-evenly'}}>
                    <div className="bar-item m-4">
                        <h1 className="statename">{stateName}</h1>
                        <p className="bold statename">Since Inception</p>
                    </div>
                </div>
                <div className="col-md-9 d-flex pt-4 pb-4" style={{justifyContent:'space-evenly'}}>
                    <div className="bar-item mb-4 mt-4">
                        <h2 className="bold">{totalDashbord?.inceptionData?.schoolsImpacted}</h2>
                        <p className="bold">Schools<br></br>Impacted</p>
                    </div>
                    <div className="bar-item mb-4 mt-4">
                        <h2 className="bold">{totalDashbord?.inceptionData?.sssKitsDonated}</h2>
                        <p className="bold">SSS Kits<br></br>Distributed</p>
                    </div>
                    <div className="bar-item mb-4 mt-4">
                        <h2 className="bold">{totalDashbord?.inceptionData?.samparkTVDonated}</h2>
                        <p className="bold">Sampark TVs<br></br>Distributed</p>
                    </div>
                    <div className="bar-item mb-4 mt-4">
                        <h2 className="bold">{totalDashbord?.inceptionData?.teachersTrained}</h2>
                        <p className="bold">Teachers<br></br>Trained</p>
                    </div>
                    <div className="bar-item mb-4 mt-4">
                        <h2 className="bold">{totalDashbord?.activeYears}</h2>
                        <p className="bold">Active<br></br>Years</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">

                    {/* <!-- First Section --> */}
                    <div className="row datasection">
                        <div className="col-md-3 left-bg-1 d-flex align-items-center justify-content-center mb-4">
                            <div className="side-l-t p-4">
                                <div className="side-l-t-content white ct">
                                    <p className="bold">1. TEACHERS<br />FEEDBACK</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 mb-4">
                            <div className="card shadow">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-body p-4 mb-4">
                                            <div className="row">
                                                <div className="col-lg-12 d-flex justify-content-center">
                                                    <div className="rating-card-start">
                                                        <div className="rating-text">
                                                            <div className="rating-heading">
                                                                Rating
                                                            </div>
                                                            <div className="rating-number">
                                                            {`${parseFloat(totalDashbord?.teacherFeedback?.overall_rating?.toFixed(2))}`}
                                                            </div>                        
                                                        </div>
                                                        <div className="ratings">
                                                        {returnStar((totalDashbord?.teacherFeedback?.overall_rating))}
                                                            {/* <i className="fa fa-star rating-color"></i>
                                                            <i className="fa fa-star rating-color"></i>
                                                            <i className="fa fa-star rating-color"></i>
                                                            <i className="fa fa-star rating-color"></i>
                                                            <i className="fa fa-star"></i> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer white bg-sec ct pb-2 pt-2">
                                <p className="m-0">SMS Feedback by <b>{`${parseFloat(totalDashbord?.teacherFeedback?.overall_feedback?.toFixed(2))}`}</b> teachers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Second Section --> */}
                    <div className="row datasection">
                        <div className="col-md-3 left-bg-2 d-flex align-items-center justify-content-center mb-4">
                            <div className="side-l-t p-4">
                                <div className="side-l-t-content white ct">
                                    <p className="bold">2. SCHOOLS WITH TRAINED TEACHERS</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 mb-4">
                            <div className="card shadow">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-body mb-4 mt-4">
                                            <div className="row">
                                                <div className="col-md-6 ct align-items-center justify-content-center">
                                                    <ProgressGraph width={200} progress={totalDashbord?.schoolsImpacted > 0 ? parseFloat((totalDashbord?.totalTrainedSchools/totalDashbord?.schoolsImpacted*100)?.toFixed(2)) : 0}/>
                                                    <p className="m-0 bold themecolor">
                                                    <img src={iconv} alt="iconv"  />
                                                    </p>
                                                    <p className="m-0 bold themecolor small">Till Date</p>
                                                    <p className="m-0 bold small">{totalDashbord?.totalTrainedSchools}/{totalDashbord?.schoolsImpacted}</p>
                                                </div>
                                                <div className="col-md-6 ct align-items-center bl justify-content-center">
                                                    <ProgressGraph width={200} progress={totalDashbord?.schoolsImpacted > 0 ? parseFloat((totalDashbord?.trainedSchools/totalDashbord?.schoolsImpacted*100)?.toFixed(2)) : 0}/>
                                                    <p className="m-0 bold themecolor">
                                                    <img src={iconv} alt="iconv"  />
                                                    </p>
                                                    <p className="m-0 bold themecolor small">This Year</p>
                                                    <p className="m-0 bold small">{totalDashbord?.trainedSchools}/{totalDashbord?.schoolsImpacted}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer white bg-sec ct pb-2 pt-2">
                                    <p className="m-0">Schools have trained teachers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- THIRD Section --> */}
                    <div className="row datasection">
                        <div className="col-md-3 left-bg-3 d-flex align-items-center justify-content-center mb-4">
                            <div className="side-l-t p-4">
                                <div className="side-l-t-content white ct">
                                    <p className="bold">3. TEACHING AS PER ACADEMIC PLAN - % OF SCHOOLS</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 mb-4">
                            <div className="card shadow">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <div className="row mb-2 mt-4">
                                                <div className="col-md-6 ct align-items-center br justify-content-center">
                                                    <ProgressGraph width={200} progress={parseFloat(totalDashbord?.samparkTVData?.tv_utilization?.class15_average?.toFixed(2))}/>
                                                    <p className="m-0 bold themecolor small">Class 1-5</p>
                                                </div>
                                                <div className="col-md-6 ct align-items-center justify-content-center">
                                                    <ProgressGraph width={200} progress={parseFloat(totalDashbord?.samparkTVData?.tv_utilization?.class68_average?.toFixed(2))} isNA={totalDashbord?.upperPrimarySTVSchools === 0 ? 'YES' : 'NO'}/>
                                                    <p className="m-0 bold themecolor small">Class 6-8</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer white bg-sec ct pb-2 pt-2">
                                    <p className="m-0">Schools follow "Sahi Kram Sahi Dhang"</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fourth Section --> */}
                    <div className="row datasection">
                        <div className="col-md-3 left-bg-4 d-flex align-items-center justify-content-center mb-4">
                            <div className="side-l-t p-4">
                                <div className="side-l-t-content white ct">
                                    <p className="bold">4. SMART CLASS ACTIVE SCHOOLS</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-9 mb-4">
                            <div className="card shadow">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-body mb-4 mt-4">
                                            <div className="row">
                                                <div className="col-md-6 ct align-items-center justify-content-center">
                                                    <ProgressGraph width={200} progress={totalDashbord?.tvDistributed > 0 ? parseFloat(((totalDashbord?.tvDistributed - totalDashbord?.samparkTVData?.total_installed)/totalDashbord?.tvDistributed*100)?.toFixed(2)) : 0}/>
                                                    <p className="m-0 bold themecolor">
                                                    <img src={iconv} alt="iconv"  />
                                                    </p>
                                                    <p className="m-0 bold themecolor small">% of Zero Usage Schools</p>
                                                    <p className="m-0 bold small">{(totalDashbord?.tvDistributed - totalDashbord?.samparkTVData?.total_installed)}/{totalDashbord?.tvDistributed}</p>
                                                </div>
                                                <div className="col-md-6 ct align-items-center bl justify-content-center">
                                                    <ProgressGraph width={200} progress={totalDashbord?.tvDistributed > 0 ? parseFloat((totalDashbord?.samparkTVData?.synced_monthly/totalDashbord?.tvDistributed*100)?.toFixed(2)) : 0}/>
                                                    <p className="m-0 bold themecolor">
                                                    <img src={iconv} alt="iconv"  />
                                                    </p>
                                                    <p className="m-0 bold themecolor small">% of Active Schools</p>
                                                    <p className="m-0 bold small">{totalDashbord?.samparkTVData?.synced_monthly}/{totalDashbord?.tvDistributed}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer white bg-sec ct pb-2 pt-2">
                                    <p className="m-0">Smart Class Usage Data
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- FIFTH Section --> */}
                    <div className="row datasection">
                            <div className="col-md-3 left-bg-5 d-flex align-items-center justify-content-center mb-4">
                                <div className="side-l-t p-4">
                                    <div className="side-l-t-content white ct">
                                        <p className="bold">5. % of children with grade level competence</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-9 mb-4">
                                <div className="card shadow">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <div className="card-body">
                                            <div className="row mb-2 mt-4">
                                                <div className="col-md-4 ct align-items-center br justify-content-center">
                                                    <RatioGraph width={250} type={'assessment'} less_than_50={parseFloat(totalDashbord?.assessmentData?.class13?.less_than_50)} between_50_and_75={parseFloat(totalDashbord?.assessmentData?.class13?.between_50_and_75)} greater_than_75={parseFloat(totalDashbord?.assessmentData?.class13?.greater_than_75)} />
                                                    <p className="m-0 bold themecolor small">Class 1-3</p>
                                                </div>
                                                <div className="col-md-4 ct align-items-center br justify-content-center">
                                                    <RatioGraph width={250} type={'assessment'} less_than_50={parseFloat(totalDashbord?.assessmentData?.class45?.less_than_50)} between_50_and_75={parseFloat(totalDashbord?.assessmentData?.class45?.between_50_and_75)} greater_than_75={parseFloat(totalDashbord?.assessmentData?.class45?.greater_than_75)} />
                                                    <p className="m-0 bold themecolor small">Class 4-5</p>
                                                </div>
                                                <div className="col-md-4 ct align-items-center justify-content-center">
                                                    <RatioGraph width={250} type={'assessment'} less_than_50={parseFloat(totalDashbord?.assessmentData?.class68?.less_than_50)} between_50_and_75={parseFloat(totalDashbord?.assessmentData?.class68?.between_50_and_75)} greater_than_75={parseFloat(totalDashbord?.assessmentData?.class68?.greater_than_75)} />
                                                    <p className="m-0 bold themecolor small">Class 6-8</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="ct">
  
                                        <p className="m-0" style={{fontSize:'1rem'}}><span style={{display:'inline-block',background:'#6aa6f7',width:'20px',height:'20px'}}>&nbsp;</span> A : Greater Than 75%, <span style={{display:'inline-block',background:'#2cc11e',width:'20px',height:'20px'}}>&nbsp;</span> B : Between 50% And 75%, <span style={{display:'inline-block',background:'#EC4824',width:'20px',height:'20px'}}>&nbsp;</span> C : Less Than 50%</p>
                                    </div>
                                    <div className="card-footer white bg-sec ct pb-2 pt-2">
                                        <p className="m-0">Classroom Assessment of {totalDashbord?.assessmentData?.total_students} students across {totalDashbord?.assessmentData?.total_schools} Schools</p>
                                    </div>
                                </div>
                            </div>
                        </div>            
                    
                </div>
            </div>

        </div>
    )

    return (
        <div className="printme">
            <div className="row">
                <div className="col-md-4 d-flex pt-2 pb-2" style={{justifyContent:'left'}}>
                    <div className="bar-item m-4 ct">
                        <img src={logo} alt="Logo" width={250} />
                    </div>
                </div>
                <div className="col-md-4 d-flex pt-2 pb-2" style={{justifyContent:'center'}}>
                    <h1 className="statename ct" style={{textAlign:'center', fontSize:'2rem'}}>
                        My Education Dashboard
                    </h1>
                </div>
                <div className="col-md-4 d-flex pt-2 pb-2" style={{justifyContent:'right'}}>
                    <div className="bar-item m-4">
                        <p className="red district">{currentDistrict} {currentBlock}</p>
                        {DateDisplay()}
                    </div>
                </div>
            </div>
            {districtDashboard}
        </div>
    );
}

export default MainDashboard